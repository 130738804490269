const app = {
    google: {
        map: {
            api: {
                key: 'AIzaSyDFZT1srNuwoUb1AmAhKoiyLys6seVWCmQ',
            },
            center: {
                lat: 63.439978731401936,
                lng: 10.418047512290933,
            },
            zoom: 12,
        },
    },
};

const color = {
    accents: {
        darkBlue: '#012c45',
        brandBlue: '#009AF0',
        lightBlue: '#cceffc',
        blueTint: '#007dc5',
    },
    additional: {
        lines: '#e2e2ed',
        light: '#ecf9fe',
        lighter: '#f7f7f7',
        lightest: '#f9f9f9',
    },
    brand: {
        blue: '#009AF0',
        orange: '#FAA61B',
    },
    text: {
        default: '#05273A',
        secondary: '#707386',
        graph: '#909CA4',
    },
    chart: ['#56BAF2', '#009AF0', '#1081C0', '#005382', '#012c45', '#7B1C68'],
};

const DEFAULT_COLORS = {
    officeIconColors: {
        primary: {
            default: color.text.graph,
            hover: color.accents.darkBlue,
        },
        secondary: {
            default: color.additional.lines,
            hover: color.accents.brandBlue,
        },
    },
    macroIconColors: {
        primary: {
            default: color.text.graph,
            hover: color.accents.darkBlue,
        },
        secondary: {
            default: color.additional.lines,
            hover: color.accents.brandBlue,
        },
    },
    transactionIconColors: {
        primary: {
            default: color.text.graph,
            hover: color.accents.darkBlue,
        },
        secondary: {
            default: color.additional.lighter,
            hover: color.additional.light,
        },
    },
    industryIconColors: {
        primary: {
            default: color.text.graph,
            hover: color.accents.darkBlue,
        },
        secondary: {
            default: color.additional.lines,
            hover: color.accents.brandBlue,
        },
        tertiary: {
            default: color.additional.lighter,
            hover: color.brand.orange,
        },
    },
    commerceIconColors: {
        primary: {
            default: color.text.graph,
            hover: color.accents.darkBlue,
        },
    },
    residentialIconColors: {
        primary: {
            default: color.text.graph,
            hover: color.accents.darkBlue,
        },
        secondary: {
            default: color.additional.lines,
            hover: color.accents.brandBlue,
        },
    },
};

const DEFAULT_CHART_OPTIONS = {
    chart: {
        animations: {
            enabled: false,
        },
        locales: [
            {
                name: 'nb',
                options: {
                    months: [
                        'Januar',
                        'Februar',
                        'Mars',
                        'April',
                        'Mai',
                        'Juni',
                        'Juli',
                        'August',
                        'September',
                        'Oktober',
                        'November',
                        'Desember',
                    ],
                    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'],
                    days: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
                    shortDays: ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø'],
                    toolbar: {
                        exportToSVG: 'Last ned SVG',
                        exportToPNG: 'Last ned PNG',
                        exportToCSV: 'Last ned CSV',
                        menu: 'Menu',
                        selection: 'Velg',
                        selectionZoom: 'Zoom: Velg',
                        zoomIn: 'Zoome inn',
                        zoomOut: 'Zoome ut',
                        pan: 'Skyving',
                        reset: 'Start på nytt',
                    },
                },
            },
        ],
        defaultLocale: 'nb',
        fontFamily: 'DM Sans, Helvetica, Arial, sans-serif',
        foreColor: color.text.graph,
    },
    colors: color.chart,
    dataLabels: {
        enabled: false,
    },
    fill: {
        opacity: 1,
    },
    grid: {
        show: true,
        borderColor: color.additional.lines,
        strokeDashArray: '2,4',
        position: 'back',
        xaxis: {
            lines: {
                show: false,
            },
        },
        yaxis: {
            lines: {
                show: true,
            },
        },
    },
    plotOptions: {
        bar: {
            columnWidth: '50%',
        },
    },
    states: {
        hover: {
            filter: {
                type: 'none',
            },
        },
    },
    tooltip: {
        theme: 'dark',
    },
    legend: {
        fontSize: '14px',
        fontWeight: 700,
    },
    xaxis: {
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
        labels: {
            hideOverlappingLabels: true,
            rotate: -45,
            rotateAlways: false,
            style: {
                fontSize: '12px',
                fontWeight: 700,
            },
        },
        tickAmount: 12,
        tooltip: {
            enabled: false,
        },
    },
    yaxis: {
        labels: {
            trim: true,
            style: {
                fontSize: '12px',
                fontWeight: 700,
            },
        },
    },
    responsive: [
        {
            breakpoint: 580,
            options: {
                chart: {
                    height: 320,
                },
                legend: {
                    fontSize: '9px',
                },
                xaxis: {
                    labels: {
                        style: {
                            fontSize: '9px',
                        },
                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            fontSize: '9px',
                        },
                    },
                },
            },
        },
    ],
};

export { app, color, DEFAULT_COLORS, DEFAULT_CHART_OPTIONS };
