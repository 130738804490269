import React from 'react';
import { convertTsvToColumnChartData } from '../utils';
import ApexChart from 'react-apexcharts';
import styled from 'styled-components';
import { DEFAULT_CHART_OPTIONS } from '../config';
import { merge } from 'lodash';
import { ChartType } from '../@types';

type Props = {
    tsvData: string;
    chartType: string;
    unit?: string;
    stacked?: boolean;
    hundredPercent?: boolean;
    flipped?: boolean;
    horizontal?: boolean;
};

const NorionChart = styled(ApexChart)`
    margin: 24px -24px 16px -24px;
    width: calc(100% + 48px);
    @media screen and (min-width: 768px) {
        margin: 32px 0 40px 0;
        width: 100%;
    }
`;

const Chart: React.FC<Props> = ({
    tsvData,
    chartType,
    unit = '',
    stacked = false,
    hundredPercent = false,
    flipped = false,
    horizontal = false,
}: Props) => {
    if (!tsvData) return null;
    const data = convertTsvToColumnChartData(tsvData, flipped);
    // noinspection JSUnusedGlobalSymbols
    const options = {
        xaxis: {
            categories: data.categories,
        },
        yaxis: {
            labels: {
                formatter: function (value: number, index: number) {
                    return `${value.toLocaleString('nb-NO')} ${unit}`;
                },
            },
        },
        chart: {
            type: chartType,
            stacked,
            stackType: hundredPercent ? '100%' : 'normal',
        },
        plotOptions: {
            bar: {
                horizontal,
            },
        },
        stroke: {
            width: chartType === ChartType.Line ? 1 : 0,
        },
        responsive: [
            {
                breakpoint: 580,
                options: {
                    chart: {
                        height: 320,
                    },
                    yaxis: {
                        labels: {
                            formatter: function (value: number, index: number) {
                                return `${value.toLocaleString('nb-NO')} ${unit}`;
                            },
                            trim: true,
                            style: {
                                fontSize: '9px',
                                fontWeight: 700,
                            },
                        },
                    },
                },
            },
        ],
    };
    merge(options, DEFAULT_CHART_OPTIONS);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <NorionChart options={options} series={data.series} type={chartType} />;
};

export default Chart;
