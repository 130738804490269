import React from 'react';
import { TrendType, TrendTypeType } from '../@types';
import { color } from '../config';

interface Props {
    trend: TrendType;
    type: TrendTypeType;
}

const TrendIndicator: React.FC<Props> = ({ trend, type }: Props) => {
    let rotation: string, trendColor: string;
    switch (trend) {
        case TrendType.UP:
            rotation = '-45deg';
            break;
        case TrendType.DOWN:
            rotation = '45deg';
            break;
        default:
            rotation = '0deg';
    }
    switch (type) {
        case TrendTypeType.GOOD:
            trendColor = color.brand.blue;
            break;
        case TrendTypeType.BAD:
            trendColor = color.brand.orange;
            break;
        case TrendTypeType.NEUTRAL:
            trendColor = color.text.default;
            break;
        default:
            trendColor = color.text.default;
    }
    return (
        <svg
            viewBox="64 64 896 896"
            focusable="false"
            width="24px"
            height="16px"
            fill="currentColor"
            aria-hidden="true"
            style={{ transform: `rotate(${rotation})`, color: trendColor }}
        >
            <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z" />
        </svg>
    );
};

export default React.memo(TrendIndicator);
