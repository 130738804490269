import React, { memo, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Report, Section, URLParams } from '../@types';
import SanityContext from '../contexts/Sanity';
import UIContext from '../contexts/UI';
import Chart from '../components/Chart';
import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import { H2, H3 } from '../components/ui';
import Map from '../components/Map';
import NewOffices from '../components/NewOffices';
import ReportIntroAndKpis from '../components/ReportIntroAndKpis';
import MultiChart from '../components/MultiChart';
import { useInView } from 'react-intersection-observer';

const Container = styled.div`
    position: initial;
    max-width: 680px;
    section > h2 {
        position: sticky;
        top: -1px;
        z-index: 998;
        margin: 4px -24px 0 -24px;
        width: calc(100% + 48px);
        padding: 16px 24px;
        background-color: rgba(255, 255, 255, 0.95);
        @media screen and (min-width: 768px) {
            margin: 48px 0 40px 0;
            padding: 16px 0;
            width: 100%;
        }

        > span {
            display: block;
        }
    }
    h3 {
        margin: 32px 0 24px 0;
        @media screen and (min-width: 768px) {
            margin: 64px 0 32px 0;
        }
    }
    > section {
        padding: 0 0 20px 0;
        border-bottom: 1px solid #e2e2ed;
        @media screen and (min-width: 768px) {
            padding: 0 0 64px 0;
        }
    }
    > section:last-child {
        border-bottom: none;
    }
`;

const Body = styled(BlockContent)`
    p {
        font-size: 1.3rem;
        margin-bottom: 1.3rem;
        line-height: 150%;
        text-align: justify;
        :last-child {
            margin-bottom: 0;
        }
        @media screen and (min-width: 768px) {
            font-size: 1.8rem;
            margin-bottom: 1.8rem;
        }
    }
`;

type ReportSectionProps = {
    title: string;
    subTitle: string;
    elId: string;
    data: Section;
    setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
};

const ReportSection: React.FC<ReportSectionProps> = ({
    title,
    elId,
    data,
    setCurrentSection,
    subTitle,
}: ReportSectionProps) => {
    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0,
    });

    useEffect(() => {
        if (inView) {
            setCurrentSection(elId);
        }
    }, [inView, elId]);

    return (
        <section>
            <H2 id={elId} ref={ref}>
                {title}
                <span>{subTitle}</span>
            </H2>
            <Section section={data} />
            {elId === 'kontor' && data.newOffices && <NewOffices offices={data.newOffices} />}
        </section>
    );
};

type SectionProps = {
    section: Section;
};

const Section: React.FC<SectionProps> = memo(({ section }: SectionProps) => {
    return (
        <div>
            {section.subSections &&
                section.subSections.map((subSection) => (
                    <div key={subSection._key}>
                        <H3>{subSection.name}</H3>
                        <Chart
                            tsvData={subSection.chartData}
                            chartType={subSection.chartType}
                            unit={subSection.unit}
                            stacked={subSection.stacked}
                            hundredPercent={subSection.hundredPercent}
                            flipped={subSection.flipped}
                            horizontal={subSection.horizontal}
                        />
                        {subSection.multiChart && subSection.multiChart.isVisible && (
                            <MultiChart input={subSection.multiChart} />
                        )}
                        {subSection.map && <Map mapData={subSection.map} />}
                        {subSection.body && (
                            <Body blocks={subSection.body} className="body" renderContainerOnSingleChild={true} />
                        )}
                    </div>
                ))}
        </div>
    );
});

type ReportProps = {
    setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
};

const Report: React.FC<ReportProps> = ({ setCurrentSection }: ReportProps) => {
    const { slug } = useParams<URLParams>();
    const { reports } = useContext(SanityContext);
    const [report, setReport] = useState<Report | null>(null);
    const { setTitle } = useContext(UIContext);

    useEffect(() => {
        if (reports) {
            const currentReport = reports.filter((report) => report.slug.current === slug)[0];
            setReport(currentReport);
            if (setTitle) {
                setTitle(currentReport.title);
            }
        }
    }, [slug]);

    if (!report) return null;

    return (
        <Container>
            <ReportIntroAndKpis content={report.reportHeader} />
            {report.office && (
                <ReportSection
                    elId="kontor"
                    title="Kontormarkedet"
                    subTitle={report.title}
                    data={report.office}
                    setCurrentSection={setCurrentSection}
                />
            )}
            {report.transaction && (
                <ReportSection
                    elId="transaksjon"
                    title="Transaksjonsmarkedet"
                    subTitle={report.title}
                    data={report.transaction}
                    setCurrentSection={setCurrentSection}
                />
            )}
            {report.macro && (
                <ReportSection
                    elId="makro"
                    title="Makro"
                    subTitle={report.title}
                    data={report.macro}
                    setCurrentSection={setCurrentSection}
                />
            )}
            {report.industry && (
                <ReportSection
                    elId="lager-logistikk-industri"
                    title="Lager / logistikk"
                    subTitle={report.title}
                    data={report.industry}
                    setCurrentSection={setCurrentSection}
                />
            )}
            {report.commerce && (
                <ReportSection
                    elId="handel"
                    title="Handel"
                    subTitle={report.title}
                    data={report.commerce}
                    setCurrentSection={setCurrentSection}
                />
            )}
            {report.residential && (
                <ReportSection
                    elId="bolig"
                    title="Bolig"
                    subTitle={report.title}
                    data={report.residential}
                    setCurrentSection={setCurrentSection}
                />
            )}
        </Container>
    );
};

export default Report;
