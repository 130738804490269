import React, { useEffect, useState } from "react";
import styled from 'styled-components';

const ScrollToTopContainer = styled.div`
    position: fixed;
    bottom: 40px;
    right: 40px;
    cursor: pointer;
`

export default function ScrollToTop() {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <ScrollToTopContainer>
            {isVisible &&
                <div onClick={scrollToTop}>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="20" cy="20" r="20" fill="#595959" />
                        <path d="M19 14.101C18.8565 14.1856 18.7213 14.2901 18.5982 14.4142L13.6921 19.364C13.305 19.7545 12.6774 19.7545 12.2903 19.364C11.9032 18.9734 11.9032 18.3403 12.2903 17.9497L18.5982 11.5858C19.3724 10.8047 20.6276 10.8047 21.4018 11.5858L27.7097 17.9497C28.0968 18.3403 28.0968 18.9734 27.7097 19.364C27.3226 19.7545 26.695 19.7545 26.3079 19.364L21.4018 14.4142C21.2787 14.2901 21.1435 14.1857 21 14.101L21 28C21 28.5523 20.5523 29 20 29C19.4477 29 19 28.5523 19 28L19 14.101Z" fill="#ffffff" />
                    </svg>
                </div>}
        </ScrollToTopContainer>
    );
}
