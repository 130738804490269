import styled from 'styled-components';

export const H1 = styled.h1`
    font-size: 1.7rem;
    font-weight: 700;
    line-height: 125%;
    @media screen and (min-width: 768px) {
        font-size: 3.2rem;
    } 
`;

export const H2 = styled.h2`
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 125%;
    & > span {
        display: block;
        color: #707386;
        font-weight: 400;
        font-size: 0.6em;
        display: none;
    }
    @media screen and (min-width: 768px) {
        font-size: 2.4rem;
    }

`;

export const H3 = styled.h3`
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 125%;
    @media screen and (min-width: 768px) {
        font-size: 1.8rem;
    }
`;
