import React from 'react';
import GoogleMapReact from 'google-map-react';
import { H3 } from './ui';
import { Office } from '../@types';
import { app } from '../config';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

const MapContainer = styled.div`
    width: 100%;
    height: 480px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Marker = styled.div<any>`
    width: fit-content;
    cursor: pointer;
`;

type Props = {
    offices: Office[];
};

const NewOffices: React.FC<Props> = ({ offices }: Props) => {
    const { map } = app.google;
    if (!offices || offices.length === 0) return null;

    function info(office: Office) {
        return `<h4 style="margin-bottom: 16px">${office.streetAddress}</h4>
                <table style="margin-bottom: 16px">
                    <tr>
                        <td><b>Areal:</b></td>
                        <td>${office.size}</td>
                    </tr>
                    <tr>
                        <td><b>Utleiegrad:</b></td>
                        <td>${office.rentalPercentage}</td>
                    </tr>
                    <tr>
                        <td><b>Ferdigstilles:</b></td>
                        <td>${office.completionYear}</td>
                    </tr>
                </table>
                <p><b>Reguleringsstatus:</b> ${office.status}</p>
                <p><b>Utbygger:</b> ${office.entrepreneur}</p>`;
    }

    return (
        <>
            <H3>Nye kontorbygg</H3>
            <MapContainer>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: map.api.key }}
                    defaultCenter={map.center}
                    defaultZoom={map.zoom}
                >
                    {offices.map((office) => (
                        <Marker
                            key={office._key}
                            lat={office.location.lat}
                            lng={office.location.lng}
                            data-tip={info(office)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 32"
                                style={{ width: '32px', height: '32px' }}
                            >
                                <defs />
                                <g filter="url(#filter0_f)" opacity=".4">
                                    <ellipse cx="10" cy="26" fill="#595959" rx="4" ry="2" />
                                </g>
                                <path
                                    fill="#909CA4"
                                    stroke="#fff"
                                    d="M19.5 10c0 4.095-2.179 8.491-4.536 11.903-1.173 1.697-2.375 3.13-3.34 4.133-.484.503-.901.891-1.222 1.15-.16.13-.288.22-.38.275l-.022.013a3.434 3.434 0 01-.402-.288c-.32-.259-.739-.647-1.222-1.15-.965-1.003-2.167-2.436-3.34-4.133C2.68 18.491.5 14.096.5 10a9.5 9.5 0 1119 0zm-9.427 17.51a.029.029 0 010 0z"
                                />
                                <circle cx="10" cy="10" r="4" fill="#fff" />
                                <defs>
                                    <filter
                                        id="filter0_f"
                                        width="16"
                                        height="12"
                                        x="2"
                                        y="20"
                                        colorInterpolationFilters="sRGB"
                                        filterUnits="userSpaceOnUse"
                                    >
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                        <feGaussianBlur result="effect1_foregroundBlur" stdDeviation="2" />
                                    </filter>
                                </defs>
                            </svg>
                        </Marker>
                    ))}
                    <ReactTooltip
                        html={true}
                        delayShow={100}
                        delayHide={300}
                        effect="float"
                        className="norion-tooltip"
                    />
                </GoogleMapReact>
            </MapContainer>
        </>
    );
};

export default NewOffices;
