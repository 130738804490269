import React from 'react';
import ReportSelector from './ReportSelector';
import { CommerceIcon, IndustryIcon, MacroIcon, OfficeIcon, ResidentialIcon, TransactionIcon } from './Icon';
import styled from 'styled-components';
import { color } from '../config';

const Container = styled.nav<{ show: boolean }>`
    background-color: white;
    transition: transform 0.3s ease-in-out;
    transform: translateY(${(props) => (props.show ? 0 : 'calc(-50vh + 64px)')});
    height: calc(50vh - 64px);
    position: relative;
    /*overflow: hidden;*/
    z-index: 500;
    padding: 0 16px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: calc(100% - 16px);
        height: 1px;
        background-color: #e2e2ed;
    }
    @media screen and (min-width: 768px) {
        background-color: transparent;
        transform: translateY(-40px);
        position: fixed;
        max-width: 100px;
        overflow: revert;
        .selector {
            display: none;
        }
        &:after {
            background-color: transparent;
        }
    }
`;

const Links = styled.div`
    width: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.6rem;
    a {
        transition-duration: 0.3s;
        transition-property: color;
        transition-timing-function: ease-in-out;
        font-size: 1.2rem;
        font-weight: 400;
        text-decoration: none;
        color: ${color.accents.blueTint};
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 7.2rem;
        letter-spacing: 1px;
        margin: 0 0 8px 0;
        &:last-child {
            margin: 0;
        }
        &:hover,
        &.active {
            color: ${color.accents.blueTint};
        }
        & div {
            margin: 0 0 4px 0;
        }
        & svg {
            opacity: 1;
        }
        &:hover svg,
        &.active svg {
            opacity: 1;
        }
    }
    @media screen and (min-width: 768px) {
        grid-template-columns: 1fr;
        margin: 56px 0 0 0;
    }
`;

type Props = {
    show: boolean;
    onChange: () => void;
    currentSection: string;
};

const Menu: React.FC<Props> = ({ show, onChange, currentSection }: Props) => {
    // noinspection HtmlUnknownAnchorTarget
    return (
        <Container show={show}>
            <ReportSelector onChange={onChange} />
            <Links onClick={onChange}>
                <a href="#kontor" className={`nav_kontor ${currentSection === 'kontor' ? 'active' : ''}`}>
                    <OfficeIcon />
                    Kontor
                </a>
                <a
                    href="#transaksjon"
                    className={`nav_transaksjon ${currentSection === 'transaksjon' ? 'active' : ''}`}
                >
                    <TransactionIcon />
                    Transaksjon
                </a>
                <a href="#makro" className={`nav_makro ${currentSection === 'makro' ? 'active' : ''}`}>
                    <MacroIcon />
                    Makro
                </a>
                <a
                    href="#lager-logistikk-industri"
                    className={`nav_lager ${currentSection === 'lager-logistikk-industri' ? 'active' : ''}`}
                >
                    <IndustryIcon />
                    Lager / logistikk
                </a>
                <a href="#handel" className={`nav_handel ${currentSection === 'handel' ? 'active' : ''}`}>
                    <CommerceIcon />
                    Handel
                </a>
                <a href="#bolig" className={`nav_bolig ${currentSection === 'bolig' ? 'active' : ''}`}>
                    <ResidentialIcon />
                    Bolig
                </a>
            </Links>
        </Container>
    );
};

export default Menu;
