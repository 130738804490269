export type Kpi = {
    _key: string;
    _type: 'kpi';
    name: string;
    trend: TrendType;
    trendType: TrendTypeType;
    value: string;
};

export type DataRow = {
    data: number[];
    name: string;
    type: 'column' | 'line';
};

export type MultiChart = {
    isVisible: boolean;
    title: string;
    chartData: string;
    yaxis: {
        left: {
            title: string;
            unit: string;
        };
        right: {
            title: string;
            unit: string;
        };
    };
};

export type Office = {
    _key: string;
    _type: 'office';
    city: string;
    completionYear: string;
    entrepreneur: string;
    location: {
        _type: 'geopoint';
        lat: number;
        lng: number;
    };
    rentalPercentage: string;
    size: string;
    status: string;
    streetAddress: string;
    zip: string;
};

export type SubSection = {
    _key: string;
    _type: 'subSection';
    name: string;
    chartType: string;
    unit: string;
    chartData: string;
    stacked: boolean;
    hundredPercent: boolean;
    flipped: boolean;
    horizontal: boolean;
    body: unknown[];
    map: MapDataType;
    multiChart: MultiChart;
};

export type Section = {
    _rev: string;
    _updatedAt: Date;
    subSections: SubSection[];
    newOffices: Office[];
};

export type URLParams = {
    slug: string;
};

export type Slug = {
    _type: 'slug';
    current: string;
};

export type IconColorType = {
    default: string;
    hover: string;
};

export type IconColorFillType = {
    primary: IconColorType;
    secondary?: IconColorType;
    tertiary?: IconColorType;
};

export type MapDataType = {
    name: string;
    svg: string;
    data: {
        _key: string;
        id: string;
        name: string;
        description: string;
        content: unknown[];
    }[];
};

export type ReportHeader = {
    kpiList: Kpi[];
    text: unknown[];
};

export type Report = {
    _id: string;
    _createdAt: Date;
    _type: 'report';
    sections: Section[];
    _rev: string;
    _updatedAt: Date;
    reportHeader: ReportHeader;
    title: string;
    slug: Slug;
    office?: Section;
    transaction?: Section;
    macro?: Section;
    industry?: Section;
    commerce?: Section;
    residential?: Section;
    hideInMenu?: boolean;
};

export enum ChartType {
    Bar = 'bar',
    Line = 'line',
}

export enum TrendType {
    UP = 'up',
    DOWN = 'down',
    FLAT = 'flat',
}

export enum TrendTypeType {
    GOOD = 'good',
    BAD = 'bad',
    NEUTRAL = 'neutral',
}
