// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertTsvToColumnChartData(tsv: string, flipped: boolean): { series: any; categories: any } {
    const data = tsv.split('\n').map((line) => line.split('\t'));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    const headers = data.shift().filter((item: string) => item.trim() !== '');
    const series: { name: string; data: (number | null)[] }[] = [];
    const categories: (string | undefined)[] = [];

    if (flipped) {
        headers.forEach((header) => {
            categories.push(header);
        });
        data.forEach((row) => {
            const data = row.map((value, index) => (index > 0 ? parseFloat(value.replace(',', '.')) : null));
            data.shift();
            series.push({
                name: row[0],
                data,
            });
        });
    } else {
        headers.forEach((header) => {
            series.push({ name: header, data: [] });
        });
        data.forEach((row) => {
            categories.push(row.shift());
            row.forEach((value, index) => {
                series[index].data.push(parseFloat(value.replace(',', '.')));
            });
        });
    }

    return { series, categories };
}
