import React from 'react';
import { DataRow, MultiChart } from '../@types';
import { convertTsvToColumnChartData } from '../utils';
import styled from 'styled-components';
import ApexChart from 'react-apexcharts';
import { merge } from 'lodash';
import { DEFAULT_CHART_OPTIONS } from '../config';

const NorionChart = styled(ApexChart)`
    margin: 24px -24px 16px -24px;
    width: calc(100% + 48px);
    @media screen and (min-width: 768px) {
        margin: 32px 0 40px 0;
        width: 100%;
    }
`;

type Props = {
    input: MultiChart;
};

const MultiChart: React.FC<Props> = ({ input }: Props) => {
    const data = convertTsvToColumnChartData(input.chartData, false);

    const maxIndex = data.series.length - 1;
    const strokeWidth: number[] = [];
    const yaxis: unknown[] = [];

    const series = data.series.map((row: DataRow, index: number) => {
        const onLastIndice = index === maxIndex;
        row.type = !onLastIndice ? 'column' : 'line';
        strokeWidth.push(!onLastIndice ? 0 : 2);
        if (index === 0) {
            yaxis.push({
                seriesName: row.name,
                title: {
                    text: input.yaxis.left.title || '',
                },
                labels: {
                    formatter: function (value: number) {
                        return `${value.toLocaleString('nb-NO')} ${input.yaxis.left.unit || ''}`;
                    },
                },
            });
        } else if (index > 0 && index < maxIndex) {
            yaxis.push({
                seriesName: data.series[0].name,
                show: false,
                labels: {
                    formatter: function (value: number) {
                        return `${value.toLocaleString('nb-NO')} ${input.yaxis.left.unit || ''}`;
                    },
                },
            });
        } else {
            yaxis.push({
                opposite: true,
                seriesName: row.name,
                title: {
                    text: input.yaxis.right.title || '',
                },
                labels: {
                    formatter: function (value: number) {
                        return `${value.toLocaleString('nb-NO')} ${input.yaxis.right.unit || ''}`;
                    },
                },
            });
        }
        return row;
    });

    const options = {
        title: {
            text: input.title || '',
        },
        chart: {
            type: 'column',
            stacked: true,
        },
        stroke: {
            width: strokeWidth,
        },
        xaxis: {
            categories: data.categories,
        },
        yaxis,
    };

    merge(options, DEFAULT_CHART_OPTIONS);

    return (
        <div>
            <NorionChart options={options} series={series} />
        </div>
    );
};

export default MultiChart;
