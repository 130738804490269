import React, { ReactNode, useState } from 'react';

type Values = {
    title: string;
    setTitle: ((value: string) => void) | null;
};

type Props = {
    children: ReactNode;
};

const UIContext = React.createContext<Values>({
    title: '',
    setTitle: null,
});

const UIProvider: React.FC<Props> = ({ children }: Props) => {
    const [title, setTitle] = useState<string>('Norion markedsrapporter');

    return <UIContext.Provider value={{ title, setTitle }}>{children}</UIContext.Provider>;
};

export { UIProvider };

export default UIContext;
