import React from 'react';
import { ReportHeader, TrendType } from '../@types';
import styled from 'styled-components';
import { color } from '../config';
import BlockContent from '@sanity/block-content-to-react';
import TrendIndicator from './TrendIndicator';

const Container = styled.section`
    margin-top: 48px;
`;

const Kpis = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    @media screen and (min-width: 768px) {
        justify-content: space-around;
        flex-flow: row nowrap;
    }
`;

const Kpi = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    padding-bottom: 24px;
    :not(:last-child) {
        padding-right: 24px;
        @media screen and (min-width: 768px) {
            padding-right: 32px;
        }
    }
    @media screen and (min-width: 768px) {
        width: auto;
        padding-bottom: 0;
    }
    > div:first-child {
        color: ${color.text.secondary};
        margin: auto auto auto 0;
        font-size: 1.1rem;
        @media screen and (min-width: 768px) {
            font-size: 1.5rem;
        }
    }
    > div:nth-child(2) {
        display: flex;
        justify-content: space-between;
        margin: auto auto auto 0;
        align-items: center;
        font-size: 2.2rem;
        font-weight: 700;
        color: ${color.text.default};
        @media screen and (min-width: 768px) {
            font-size: 3.6rem;
        }
    }
`;

const Text = styled(BlockContent)`
    p {
        font-size: 1.4rem;
        margin-bottom: 1.3rem;
        line-height: 150%;
        text-align: justify;
        :last-child {
            margin-bottom: 0;
        }
        @media screen and (min-width: 768px) {
            font-size: 2rem;
            margin-bottom: 1.8rem;
        }
    }
`;

type Props = {
    content: ReportHeader;
};

const ReportIntroAndKpis: React.FC<Props> = ({ content }: Props) => {
    if (!content) return null;
    const { kpiList, text } = content;
    return (
        <Container>
            <Kpis>
                {kpiList &&
                    kpiList.map((kpi) => (
                        <Kpi key={kpi._key}>
                            <div>{kpi.name}</div>
                            <div>
                                <div>{kpi.value}</div>
                                {kpi.trend && <TrendIndicator trend={kpi.trend} type={kpi.trendType} />}
                            </div>
                        </Kpi>
                    ))}
            </Kpis>
            <Text blocks={text} renderContainerOnSingleChild={true} />
        </Container>
    );
};

export default ReportIntroAndKpis;
