import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import App from './components/App';
import { SanityProvider } from './contexts/Sanity';
import { UIProvider } from './contexts/UI';

render(
    <StrictMode>
        <SanityProvider>
            <UIProvider>
                <App />
            </UIProvider>
        </SanityProvider>
    </StrictMode>,
    document.getElementById('root'),
);
