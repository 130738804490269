import React, { ChangeEvent, useContext } from 'react';
import SanityContext from '../contexts/Sanity';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { color } from '../config';

const Select = styled.div`
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    select {
        transition: all 0.3s ease-in-out;
        border: 2px solid ${color.additional.lines};
        border-radius: 24px;
        height: 48px;
        padding: 0 48px 0 16px;
        appearance: none;
        font-weight: 700;
        outline: none;
        cursor: pointer;
    }
    svg {
        transition: all 0.3s ease-in-out;
        position: relative;
        right: 32px;
        pointer-events: none;
    }
    :hover {
        select {
            background-color: ${color.accents.lightBlue};
            border-color: ${color.accents.brandBlue};
            white-space: nowrap;
            text-overflow: fade;
        }
        svg {
            path {
                fill: ${color.accents.darkBlue};
            }
        }
    }
`;

type Props = {
    onChange: () => void;
};

const ReportSelector: React.FC<Props> = ({ onChange }: Props) => {
    const { reports } = useContext(SanityContext);
    const history = useHistory();

    function handleChange(e: ChangeEvent<HTMLSelectElement>) {
        const slug = e.currentTarget.value;
        if (!slug) return;
        history.push(`/${slug}`);
        onChange();
    }

    return (
        <Select className="selector">
            <select id="links" placeholder="Velg markedsrapport" onChange={handleChange}>
                {reports &&
                    reports.map((report) => {
                        if (report.hideInMenu) return null;
                        return (
                            <option key={report.slug.current} value={report.slug.current}>
                                {report.title}
                            </option>
                        );
                    })}
            </select>
            <svg width="10" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.375 10.25c.515 0 .867.234 1.054.703.211.469.141.879-.21 1.23L5.842 15.56c-.234.21-.503.316-.808.316-.305 0-.574-.105-.809-.316L.851 12.184c-.351-.352-.433-.762-.246-1.23.211-.47.574-.704 1.09-.704h2.742V.547c0-.281.14-.422.422-.422h.352c.28 0 .421.14.421.422v9.703h2.743zm-3.34 4.5l3.375-3.375H1.66l3.375 3.375z"
                    fill={color.text.secondary}
                />
            </svg>
        </Select>
    );
};

export default ReportSelector;
