import React, { memo } from 'react';
import { MapDataType } from '../@types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import blocksToHtml from '@sanity/block-content-to-html';
import { SANITY_DATASET, SANITY_PROJECT_ID } from '../contexts/Sanity';

const Container = styled.div`
    margin: 24px -24px 16px -24px;
    width: calc(100% + 48px);
    @media screen and (min-width: 768px) {
        margin: 32px 0 40px 0;
        width: 100%;
    }
    svg {
        width: 100%;
        height: auto !important;
        .area {
            cursor: pointer;
            transition: fill 0.3s ease-in-out;
            :hover {
                fill: #faa61b;
            }
        }
    }
`;

function createSVGMarkup(markup: string) {
    return {
        __html: markup,
    };
}

function getInfo(content: unknown[]) {
    if (!content) return '';
    return blocksToHtml({
        blocks: content,
    });
}

function doMagic(mapData: MapDataType): string {
    const { svg, data } = mapData;
    const div = document.createElement('div');
    div.innerHTML = svg;

    if (data) {
        data.map((item) => {
            const area = div.querySelector(`#${item.id}`);
            area?.setAttribute('class', 'area');
            area?.setAttribute(
                'data-tip',
                `<h2>${item.name}</h2><div class="rich-infobox">${getInfo(item.content)}</div>`,
            );
        });
    }

    return div.innerHTML;
}

type Props = {
    mapData: MapDataType;
};

const Map: React.FC<Props> = ({ mapData }: Props) => {
    return (
        <>
            <Container dangerouslySetInnerHTML={createSVGMarkup(doMagic(mapData))} />
            {mapData.data && (
                <ReactTooltip html={true} delayShow={100} delayHide={300} effect="float" className="norion-tooltip" />
            )}
        </>
    );
};

export default memo(Map);
